
























































import BaseButton from '@/components/base/BaseButton.vue';
import { useAuthStore } from '@/composables/useAuthStore';
import { Reservation } from '@/models/booked/Reservation';
import { defineComponent, onMounted, PropType, ref } from '@/plugins/composition';
import { rules } from '@/composables/useValidation/validations';
import { useApi } from '@/composables/useApi';
import { useLoader } from '@/composables/useLoader';

export default defineComponent({
  components: { BaseButton },
  name: 'UpdateBooking',
  props: {
    reservation: {
      type: Object as PropType<Reservation>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { user } = useAuthStore();
    const valid = ref(false);
    const success = ref(false);
    const formData = ref({
      reference: props.reservation.referenceNumber,
      name: '',
      email: '',
      message: '',
    });

    const { post } = useApi();

    const { loading, withLoader } = useLoader();

    onMounted(() => {
      formData.value.name = user.value.data.firstName + ' ' + user.value.data.lastName;
      formData.value.email = user.value.data.email;
    });

    const submit = async () =>
      withLoader(async () => {
        const response = await post('/reservations/update', formData.value);
        success.value = true;
      });

    const cancel = () => {
      emit('input');
    };

    return {
      user,
      formData,
      valid,
      cancel,
      rules,
      submit,
      loading,
      success,
      emit,
    };
  },
});
