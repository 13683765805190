
































import BaseButton from '@/components/base/BaseButton.vue';
import { Reservation } from '@/models/booked/Reservation';
import { computed, defineComponent, PropType, ref } from '@/plugins/composition';
import { formatDate, formatTimeRange } from '@/utils/date';
import { isBefore, parseISO } from 'date-fns';
import UpdateBooking from './UpdateBooking.vue';

export default defineComponent({
  components: { BaseButton, UpdateBooking },
  name: 'ReservationCard',
  props: {
    reservation: {
      type: Object as PropType<Reservation>,
      required: true,
    },
    noUpdate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const expanded = ref(false);
    const updating = ref<Reservation | null>(null);

    const toggleExpand = () => {
      expanded.value = !expanded.value;
    };
    const date = computed(() => {
      return formatDate(props.reservation.startDate);
    });
    const time = computed(() => {
      return formatTimeRange(props.reservation.startDate, props.reservation.endDate, true);
    });
    const update = () => {
      updating.value = props.reservation;
    };

    const canUpdate = computed(() => {
      return !isBefore(parseISO(props.reservation.startDate), new Date()) && !props.noUpdate;
    });

    return {
      time,
      expanded,
      date,
      toggleExpand,
      update,
      updating,
      canUpdate,
    };
  },
});
